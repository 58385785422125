import React, { PureComponent } from 'react';
import Lottie from 'react-lottie';
import { Container, Row, Col } from 'react-bootstrap';
import { TimelineLite } from 'gsap';
import Observer from 'react-intersection-observer';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import '../sass/_probono.scss';
import Yellowkey from '../../static/assets/yellow_key.svg';
import LightBluekey from '../../static/assets/light_blue_key.svg';
import ProbonoCards from '../components/ProbonoCards';
import * as globalAnimation from '../animations/globalAnimation';
import * as Probono from '../animations/Probono';
import animationData from '../animations/line_animation.json';

export default class proBono extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      autoPlay: false,
    };

    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.animateHeading(this.tl, '.title', '.subtitle');
    globalAnimation.animateImageRight(
      this.tl,
      '.svg-line__line_yellow',
      '.svg_circle_yellow',
      '.porbono-image',
      '.orange_bg',
      '#title',
      '#desc'
    );
  }

  boxTwoAnimation = inView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (inView) {
      globalAnimation.animateImageLeftBox(
        tl,
        '#history-title',
        '.svg-line__line_lightblue',
        '.svg_circle_lightblue',
        '#history-description',
        '.probono-image2',
        '.lightblue_bg'
      );
    }
  };

  animateLine = inView => {
    if (inView) {
      this.setState({ autoPlay: true });
    }
  };

  boxThreeAnimation = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });

    if (InView) {
      Probono.animateImageBottomRight(
        tl,
        '.svg-line__line_yellow',
        '.svg_circle_yellow',
        '.probono-image4',
        '.probono-image3',
        '.grey_bg',
        '#engagement-title',
        '#engagement-description'
      );
    }
  };

  imageFadeAnimation = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      Probono.animateImageFade(
        tl,
        '.bg-image-container',
        '#image-title',
        '#image-description',
        '#image-logo'
      );
    }
  };

  caseStudiesAnimation = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      Probono.animateCards(tl, '.title-case-studies', '.subtitle-case-studies');
      Probono.animateCaseStudiesCards(tl, '#card');
    }
  };

  getInvolvedAnimation = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.animateHeading(tl, '.title-get-involved', '.subtitle-get-involved');
    }
  };

  blueBoxAnimation = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      Probono.animateProjectCommittee(
        tl,
        '.blue-box-animation',
        '.box-image-lightblue',
        '#committee-title',
        '#committee-description',
        '#committee-icon'
      );
    }
  };

  orangeBoxAnimation = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      Probono.animateProjectCommittee(
        tl,
        '.orange-box-animation',
        '.box-image-darkblue',
        '#project-title',
        '#project-description',
        '#team-icon'
      );
    }
  };

  render() {
    const { autoPlay } = this.state;
    const defaultOptions = {
      loop: false,
      autoplay: autoPlay,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    const {
      data: {
        markdownRemark: {
          frontmatter: {
            title,
            logo,
            containerOne,
            containerTwo,
            containerThree,
            containerFour,
            containerFour: { logo1, logo2 },
            caseStudies,
            caseStudies: { cards },
            bottomSection,
            bottomSection: { committeeSecction, teamSection },
          },
        },
      },
    } = this.props;
    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle="Pro Bono" />

        <Container fluid className="main_wrapper">
          <Row className="">
            <Col>
              <div className="wrapper">
                <div className="page_heading_section text-center">
                  <h3 className="page_heading text-uppercase d-lg-block d-none title">{title}</h3>
                  <h4 className="text-uppercase page_heading_child subtitle">{title}</h4>
                </div>
              </div>
            </Col>
          </Row>
          <Row noGutters className="pt-lg-5 my-lg-5">
            <Col xs={12} className="d-lg-none">
              <div>
                <Img
                  fluid={containerOne.image.childImageSharp.fluid}
                  className="img-fluid cover_pic"
                  alt=""
                />
              </div>
            </Col>
            <Col xs={12} lg={{ spna: 4, offset: 1 }} className="d-none d-lg-block">
              <div className="bg-orange orange_bg">
                <div className="">
                  <Img
                    fluid={containerOne.image.childImageSharp.fluid}
                    className="img-fluid porbono-image cover_pic"
                    alt=""
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} lg={{ span: 6, offset: 1 }}>
              <div className="position-relative line line-horizontal wrapper pl-0 pt-lg-0 pl-lg-5">
                <h5 id="title" style={{ color: '#0A4169' }}>
                  {containerOne.title}
                </h5>
                <div>
                  <Yellowkey className="mb-0" />
                </div>
                <p id="desc">{containerOne.descriptionOne}</p>
                <p id="desc">{containerOne.descriptionTwo}</p>
              </div>
            </Col>
          </Row>
        </Container>

        <Container fluid className="">
          <Observer onChange={this.boxTwoAnimation} triggerOnce>
            <Row className="bg-white my-lg-5 py-lg-5">
              <Col xs={12} lg={6} className="d-lg-none">
                <div className="bg-appcolor-light d-none d-lg-block box-two position-absolute" />
                <Img className="box-two-image" fluid={containerTwo.image.childImageSharp.fluid} />
              </Col>
              <Col xs={12} lg={{ span: 6, offset: 1 }} className="">
                <div className="position-relative line line-horizontal wrapper pl-0">
                  <h5 id="history-title" style={{ color: '#0A4169' }}>
                    {containerTwo.title}
                  </h5>
                  <div>
                    <LightBluekey className="mb-0" />
                  </div>
                  <p id="history-description">{containerTwo.descriptionOne}</p>
                  <p id="history-description">{containerTwo.descriptionTwo}</p>
                </div>
              </Col>

              <Col xs={12} lg={4} className="d-none d-lg-block">
                <div className="bg-appcolor-light lightblue_bg">
                  <div className="">
                    <Img
                      fluid={containerTwo.image.childImageSharp.fluid}
                      className="img-fluid probono-image2"
                      alt=""
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Observer>
        </Container>
        <Container fluid className="">
          <Observer onChange={this.boxThreeAnimation} triggerOnce>
            <Row className="bg-white py-lg-5">
              <Col xs={12} lg={6} className="d-lg-none">
                <div className="bg-appcolor-light d-none d-lg-block box-two position-absolute" />
                <Img className="box-two-image" fluid={containerThree.image.childImageSharp.fluid} />
              </Col>
              <Col xs={12} lg={{ span: 6, offset: 1 }}>
                <div className="position-relative line line-horizontal wrapper pl-0 pt-lg-0">
                  <h5 id="engagement-title" style={{ color: '#0A4169' }}>
                    {containerThree.title}
                  </h5>
                  <div>
                    <Yellowkey className="mb-0" />
                  </div>
                  <p id="engagement-description">{containerThree.descriptionOne}</p>
                  <p id="engagement-description">{containerThree.descriptionTwo}</p>
                </div>
              </Col>

              <Col xs={12} lg={4} className="pb-5 d-none d-lg-block">
                <div className="bg-grey grey_bg">
                  <Img
                    className="box-three-image-two probono-image3"
                    fluid={logo.image.childImageSharp.fluid}
                  />
                  <div className="">
                    <Img
                      fluid={containerThree.image.childImageSharp.fluid}
                      className="img-fluid probono-image4 box-three-image"
                      alt=""
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Observer>
          <Row className="bg-white position-relative bg-image-container image-wrapper">
            <Observer onChange={this.imageFadeAnimation} triggerOnce>
              <Col xs={12} lg={6} className="blue-gradient">
                <div className="wrapper pb-0 pb-lg-3 pt-lg-4">
                  <h3 id="image-title" style={{ color: '#57B7F9' }}>
                    {containerFour.title}
                  </h3>
                  <p
                    id="image-description"
                    className="text-small text-lg-large py-lg-3 mb-0"
                    style={{ color: '#ffffff' }}
                  >
                    {containerFour.description}
                  </p>
                  <div className="d-flex flex-direction-row ">
                    <div id="image-logo" className="pt-2 mr-5 logo-one">
                      <Img fluid={logo2.image.childImageSharp.fluid} />
                    </div>
                    <div id="image-logo" className="logo-two">
                      <Img fluid={logo1.image.childImageSharp.fluid} />
                    </div>
                  </div>
                </div>
              </Col>
            </Observer>
          </Row>
          <Row>
            <Col xs={12}>
              <Row className="bg-grey py-lg-5">
                <Col>
                  <div className="center">
                    <Row>
                      <Col>
                        <Observer onChange={this.caseStudiesAnimation} triggerOnce>
                          <div className="wrapper">
                            <div className="page_heading_section text-center">
                              <h3 className="page_heading text-uppercase d-lg-block d-none title-case-studies">
                                {caseStudies.title}
                              </h3>
                              <h4 className="text-uppercase page_heading_child subtitle-case-studies">
                                {caseStudies.title}
                              </h4>
                            </div>
                          </div>
                        </Observer>
                      </Col>
                    </Row>
                    <Container>
                      <Observer onChange={this.cardAnimation} triggerOnce>
                        <Row id="card" className="d-flex justify-content-center pb-lg-5 py-xs-5">
                          {cards.map((card, index) => (
                            <ProbonoCards
                              key={String(index)}
                              image={card.image}
                              logo={card.cardLogo}
                              title={card.title}
                              subtitle={card.subtitle}
                              modal={card.modal}
                            />
                          ))}
                        </Row>
                      </Observer>
                    </Container>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="">
            <Col xs={12}>
              <Observer onChange={this.getInvolvedAnimation} triggerOnce>
                <div className="wrapper px-0">
                  <div className="page_heading_section text-center">
                    <h3 className="page_heading text-uppercase d-lg-block d-none title-get-involved">
                      {bottomSection.title}
                    </h3>
                    <h4 className="text-uppercase page_heading_child subtitle-get-involved">
                      {bottomSection.title}
                    </h4>
                  </div>
                </div>
              </Observer>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Observer onChange={this.blueBoxAnimation} threshold="0" triggerOnce>
                <Row>
                  <Col xs={12} lg={3} id="box" className="position-relative py-2 py-md-0">
                    <div
                      className="bg-appcolor position-absolute blue-box-animation"
                      id="box"
                      style={{ height: '100%', width: '100%' }}
                    />
                    <div
                      style={{ left: '130px', top: '-20px' }}
                      className=" position-relative bg-appcolor-light box-image-lightblue p-5"
                      id="boxOne"
                    >
                      <img
                        src={committeeSecction.boxBlue.image.publicURL}
                        style={{ maxHeight: '74px', maxWidth: '74px' }}
                        alt=""
                      />
                    </div>
                  </Col>
                  <Col xs={12} lg={8}>
                    <div className="col-7-wrapper pr-lg-0">
                      <h5
                        id="committee-title"
                        className="text-center text-md-left"
                        style={{ color: '#0A4169' }}
                      >
                        {committeeSecction.title}
                      </h5>
                      <p id="committee-description" className="mt-3 text-center text-md-left">
                        {committeeSecction.descriptionOne}
                      </p>
                      <p id="committee-description" className="mt-3 text-center text-md-left">
                        {committeeSecction.descriptionTwo}
                      </p>
                      <Row>
                        {committeeSecction.committee.map((com, index) => (
                          <Col xs={12} lg={3} id="committee-icon" key={String(index)}>
                            <div className="d-flex justify-content-center">
                              <img
                                src={com.image.publicURL}
                                height="80px"
                                width="80px"
                                alt=""
                                className="p-3"
                              />
                            </div>
                            <p
                              style={{ lineHeight: '150%', color: '#7B7B7B' }}
                              className="text-small  mb-0 text-center"
                            >
                              {com.title}
                            </p>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Observer>
            </Col>
          </Row>
          <Observer onChange={this.animateLine} triggerOnce>
            <Row noGutters className="d-none d-lg-block">
              <Col>
                <div style={{ padding: '0 25% 0 12.5%' }}>
                  <Lottie
                    options={defaultOptions}
                    isClickToPauseDisabled
                    isStopped={this.state.isStop}
                  />
                </div>
              </Col>
            </Row>
          </Observer>
          <Row className="pb-lg-5">
            <Col xs={12}>
              <Observer onChange={this.orangeBoxAnimation} threshold="0" triggerOnce>
                <Row>
                  <Col xs={12} lg={8}>
                    <div className="col-7-wrapper pl-lg-0">
                      <h5
                        id="project-title"
                        className="text-center text-md-left"
                        style={{ color: '#0A4169' }}
                      >
                        {teamSection.title}
                      </h5>
                      <p id="project-description" className="mt-3 text-center text-md-left">
                        {teamSection.descriptionOne}
                      </p>
                      <p id="project-description" className="mt-3 text-center text-md-left">
                        {teamSection.descriptionTwo}
                      </p>
                      <Row>
                        {teamSection.team.map((com, index) => (
                          <Col id="team-icon" xs={12} lg={3} key={String(index)}>
                            <div className="d-flex justify-content-center">
                              <img
                                src={com.image.publicURL}
                                height="80px"
                                width="80px"
                                alt=""
                                className="p-3"
                              />
                            </div>
                            <p
                              style={{ lineHeight: '150%', color: '#7B7B7B' }}
                              className="text-small p-2 text-center"
                            >
                              {com.title}
                            </p>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </Col>
                  <Col xs={12} lg={3} id="box" className="position-relative py-2 py-md-0">
                    <div
                      className="bg-orange position-absolute orange-box-animation"
                      id="box"
                      style={{ height: '85%', width: '100%' }}
                    />
                    <div
                      style={{ left: '0px', top: '-20px' }}
                      className=" position-absolute box-image-darkblue bg-appcolor p-5"
                      id="boxOne"
                    >
                      <img
                        src={teamSection.boxOrange.image.publicURL}
                        style={{ maxHeight: '74px', maxWidth: '74px' }}
                        alt=""
                      />
                    </div>
                  </Col>
                </Row>
              </Observer>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "pro-bono" } }) {
      frontmatter {
        title
        logo {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        containerOne {
          title
          descriptionOne
          descriptionTwo
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        containerTwo {
          title
          descriptionOne
          descriptionTwo
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        containerThree {
          title
          descriptionOne
          descriptionTwo
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        containerFour {
          title
          description
          logo1 {
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          logo2 {
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        caseStudies {
          title
          cards {
            title
            subtitle
            modal {
              title
              description
              box {
                one {
                  title
                  description
                  image {
                    publicURL
                  }
                }
                two {
                  title
                  description
                  image {
                    publicURL
                  }
                  list
                }
                three {
                  title
                  description
                  image {
                    publicURL
                  }
                  list
                }
              }
              card {
                title
                description
                name
                info
              }
            }
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            cardLogo {
              image {
                childImageSharp {
                  fluid(maxWidth: 2048, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        bottomSection {
          title
          committeeSecction {
            title
            descriptionOne
            descriptionTwo
            boxBlue {
              image {
                publicURL
              }
            }
            committee {
              title
              image {
                publicURL
              }
            }
          }
          teamSection {
            title
            descriptionOne
            descriptionTwo
            boxOrange {
              image {
                publicURL
              }
            }
            team {
              title
              image {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;
