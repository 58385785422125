import React, { PureComponent } from 'react';
import Img from 'gatsby-image';
import { Row, Col, Card, Modal, Button, Container } from 'react-bootstrap';
import ReadMore from '../../../static/assets/whiteArrow.svg';
import './_probonocards.scss';

export default class ProbonoCards extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
  }

  handleShowModal = () => {
    document.getElementsByTagName('nav')[0].classList.remove('fixed-top');
    document.getElementsByClassName('main_wrapper')[0].style.marginTop = 0;
    this.setState({
      showModal: true,
    });
  };

  handleClose = () => {
    document.getElementsByTagName('nav')[0].classList.add('fixed-top');
    document.getElementsByClassName('main_wrapper')[0].style.marginTop = '64px';
    this.setState({
      showModal: false,
    });
  };

  render() {
    const {
      image,
      logo,
      title,
      subtitle,
      modal,
      modal: {
        box: { one, two, three },
        card,
      },
    } = this.props;
    const { showModal } = this.state;
    return (
      <Col xs={12} lg={5} className="py-4 pt-lg-0 pb-lg-4">
        <Card className="p-0 mh-100 w-100">
          <Card.Header className="bg-white border-bottom-0 p-0">
            <Img fluid={image.childImageSharp.fluid} />
            <Img className="h-25 w-25 m-3" fluid={logo.image.childImageSharp.fluid} />
          </Card.Header>
          <Card.Body>
            <Card.Title style={{ color: '#0A4169' }}>{title}</Card.Title>
            <Card.Text className="text-small" style={{ color: '#363636' }}>
              {subtitle}
            </Card.Text>
            <div className="d-flex justify-content-end">
              <Button
                onClick={this.handleShowModal}
                className=" btn-read-more border-0 text-small probono-card-button px-3"
              >
                Discover More{' '}
                <ReadMore className="text-white arrow-translate" height="8px" width="25px" />
              </Button>
            </div>
          </Card.Body>
        </Card>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          className="probono-modal"
          centered
          show={showModal}
          onHide={this.handleClose}
        >
          <Modal.Header closeButton className="border-0 pb-0" />
          <Modal.Body>
            <Container>
              <div>
                <Row className="pb-5">
                  <Col lg={{ span: 3, offset: 1 }} className="mb-3">
                    <Img fluid={logo.image.childImageSharp.fluid} />
                  </Col>
                  <Col lg={8}>
                    <h3 className="text-appcolor">{modal.title}</h3>
                    {modal.description.map((desc, index) => (
                      <h6 key={String(index)}>{desc}</h6>
                    ))}
                  </Col>
                </Row>
                <Row className="flex-lg-row flex-column">
                  <Col md={12} lg className="mx-lg-3 mb-lg-0 mb-2 bg-grey px-0">
                    <div className="d-flex justify-content-center p-2 bg-white">
                      <img src={one.image.publicURL} className="probono-icon" alt="" />
                    </div>
                    <h3 className="text-center text-appcolor pb-3 bg-white">{one.title}</h3>
                    <div className=" p-3">
                      <p>{one.description}</p>
                    </div>
                  </Col>
                  <Col md={12} lg className="mx-lg-3 mb-lg-0 mb-2 bg-grey px-0">
                    <div className="d-flex justify-content-center bg-white p-2">
                      <img src={two.image.publicURL} className="probono-icon" alt="" />
                    </div>
                    <h3 className="text-center text-appcolor pb-3 bg-white">{two.title}</h3>
                    <div className="">
                      <div>
                        <p className="mb-0 p-3">{two.description}</p>
                        <div className="circle-light-bullets-small">
                          <ul className="lightblue-bullets bg-grey pl-5 px-3">
                            {two.list.map((liText, index) => (
                              <li key={String(index)}>{liText}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={12} lg className="mx-lg-3 mb-lg-0 mb-2 bg-grey px-0">
                    <div className="d-flex justify-content-center bg-white p-2">
                      <img src={three.image.publicURL} className="probono-icon" alt="" />
                    </div>
                    <h3 className="text-center text-appcolor bg-white pb-3">{three.title}</h3>

                    <div className="">
                      <p className="mb-0 p-3">{three.description}</p>
                      <div className="circle-light-bullets-small">
                        <ul className="lightblue-bullets pl-5 px-3">
                          {three.list !== null &&
                            three.list.map((liText, index) => (
                              <li key={String(index)}>{liText}</li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
                {card !== null && (
                  <Row className="justify-content-center">
                    <Col xs={11} className="my-5 shadow p-3 p-lg-5 bg-white bg-quote rounded">
                      <div className="">
                        <div>
                          <h2 className="text-appcolor text-center text-lg-left">{card.title}</h2>
                          <div className="">
                            {card.description.map((desc, index) => (
                              <p key={String(index)}>{`"${desc}"`}</p>
                            ))}
                          </div>
                          <div className="text-right">
                            <h6 className="mb-0 text-bold text-lightblue"> {card.name}</h6>
                            <small className="text-uppercase text-muted">{card.info}</small>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
            </Container>
          </Modal.Body>
        </Modal>
      </Col>
    );
  }
}
